
.details {
    .detail-header {
        margin-top: 20px;
    }
}
.detail {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    top: -20px;
    bottom: 0;
    width: 100%;
    height: calc(100% + 20px);
    position: relative;
    background: #F7F8FC;
    .detail-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .title {
        position: relative;
        height: 50px;
        margin-top: 35px;
        img {
            width: 218px;
            height: 50px;
        }
        .text {
            position: absolute;
            left: 0;
            display: inline-block;
            width: 218px;
            height: 50px;
            line-height: 50px;
            text-align: center;
            font-size: 20px;
            color: #fff;
        }
    }
    .data-item {
        flex: 1;
        height: 1%;
    }
    .class-item {
        display: flex;
        flex-direction: column;
        background: #FFFFFF;
        height: calc(100% - 20px);
        box-shadow: 0 0 10px 0 rgba(14, 168, 94, 0.1);
        border-radius: 6px;
        margin-top: 20px;
        padding: 20px 0 20px 20px;
        box-sizing: border-box;
        .class-top {
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #2DC079;
        }
        .class-table {
            display: flex;
            flex-direction: column;
            flex: 1;
            height: 1%;
            .table-title {
                height: 51px;
                line-height: 51px;
                background: #E7F6EF;
                padding: 0 70px;
                margin-right: 30px;
            }
            .title-item {
                display: inline-block;
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #2DC079;
                text-align: center;
                &:nth-child(1) {
                    width: 40%;
                    text-align: left;
                }
                &:nth-child(2) {
                    width: 10%;
                }
                &:nth-child(3) {
                    width: 10%;
                }
                &:nth-child(4) {
                    width: 10%;
                }
                &:nth-child(5) {
                    width: 15%;
                }
                &:nth-child(6) {
                    width: 15%;
                }
            }
            .table-body {
                display: flex;
                flex-direction: column;
                flex: 1;
                height: 1%;
                ::v-deep > .el-scrollbar__wrap {
                    overflow-x: hidden;
                }
                ::v-deep > .el-scrollbar__bar.is-vertical {
                    width: 10px;
                    .el-scrollbar__thumb {
                        background-color: #2DC079;
                    }
                }
                .body-item {
                    display: flex;
                    align-items: center;
                    height: 90px;
                    padding: 0 70px;
                    border-bottom: 1px solid #EEEEEE;
                    margin-right: 30px;
                    .title-item {
                        color: #333;
                        font-weight: 400;
                    }
                    .no-pass {
                        color: #FF0000;
                        font-size: 30px;
                    }
                    .pass {
                        color: #2DC079;
                        font-size: 30px;
                    }
                    .student-name {
                        display: flex;
                        align-items: center;
                        img {
                            width: 42px;
                            height: 42px;
                            border-radius: 50%;
                            margin-right: 20px;
                        }
                    }
                }
            }
        }
        .more {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #2DC079;
            margin-top: 27px;
            cursor: pointer;
            i {
                font-size: 20px;
            }
        }
    }
}
